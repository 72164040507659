/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/css/main.css"
import "bootstrap/dist/css/bootstrap.min.css"
// import "./src/css/bootstrap.min.css"
// import "./src/css/fontawesome.min.css"
// import "./src/js/jquery-3.4.1.min.js"
// import "./src/js/plugins.js"

// import "./src/css/header.css"
// import "./src/css/hero.css"
// import "./src/css/post.css"
// import "./src/css/singlePostPage.css"
// import "./src/css/btounsi"
// import "./src/css/footer.css"
// import "./node_modules/bootstrap/dist/css/bootstrap.css";
